import { useTranslation } from 'react-i18next'

import { ErrorPage } from '../components/ErrorPage'
import { getEntriesWrapper } from '../services/contentfulClient'

const getErrorPage = (statusCode, locale) =>
  getEntriesWrapper({
    content_type: '404',
    'fields.code': statusCode,
    locale,
    include: 4,
  }).then(entries => entries.items[0])

const Custom404Page = ({ isPage, enPage }) => {
  const { i18n } = useTranslation()
  return <ErrorPage page={i18n.language === 'is' ? isPage : enPage} />
}
export async function getStaticProps() {
  const isPage = await getErrorPage(404, 'is')
  const enPage = await getErrorPage(404, 'en')

  return {
    props: {
      isPage,
      enPage,
    },
  }
}

export default Custom404Page
